
import { Options, Vue } from "vue-class-component";
import { LockClosedIcon } from "@heroicons/vue/solid";
import { LoginType, LoginTypeDefault } from "../types/user/login-type";
import { loginHandler } from "../handlers/user/access-handlers";
import store from "../store";
import { AlertSeverities, showAlert } from "@/helpers/alerts";

@Options({
  components: {
    LockClosedIcon,
  },
})
export default class Login extends Vue {
  private credentials: LoginType = LoginTypeDefault;
  private button = "Přihlásit!";

  mounted(): void {
    this.credentials.password = "";
    if (store.getters.getAccessToken != "") this.$router.push({ name: "Courses" });
  }

  async handleLogin(): Promise<void> {
    const payload = this.credentials;
    this.button = "Načítám!";

    const response = await loginHandler(payload);
    this.button = "Přihlásit!";
    if (response === undefined) return;

    showAlert("Přihlášen", "Byl jsi úspěšně přihlášen, vítej!", AlertSeverities.success);
    this.$router.push({ name: "Courses" });
  }
}
